<template>
  <q-card flat class="justify-center creative">
    <q-card-section>
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('campaign_api') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
        <q-breadcrumbs-el :label="$t('campaign_api')" exact />
      </q-breadcrumbs>
    </q-card-section>
    <q-card-section>
      <q-banner class="bg-info text-white q-mb-md" rounded>
        <q-item>
          <q-item-section top avatar>
            <q-icon name="mdi-information-outline" size="lg" />
          </q-item-section>
          <q-item-section>
            <q-item-label caption lines="3" class="text-white">
              Clique
              <a
                href="https://drive.google.com/file/d/1WBZPKidGY1EtjnJE0fIeg1Uk48_Y56gs/view"
                style="text-decoration: none"
                class="q-mt-md"
                target="_blank"
                >aqui</a
              >
              para saber mais.
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-banner>
    </q-card-section>
    <q-separator spaced />
    <q-card-section class="row q-col-gutter-md">
      <div class="col-6">
        <q-select
          use-input
          input-debounce="200"
          @filter="filterAffiliate"
          @input="retrieveAffiliateData"
          outlined
          clearable
          v-model="affiliateSelected"
          :options="affiliateFiltered"
          label="Afiliado"
          option-label="name"
        />
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.token" label="Token">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar Token
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
      <div class="col-12">
        <q-input outlined readonly type="text" :value="getApiLink" :label="$t('conversion_api_url')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLink)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar API
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
const dataLink = process.env.VUE_APP_DATA_API;

import AffiliateService from '../../../services/AffiliateService';

export default {
  mixins: [AffiliateService],
  name: 'CampaignAPIPage',
  data() {
    return {
      affiliateData: {
        email: null,
        id: null,
        token: null
      },
      apiDataUrl: dataLink,
      wasAffiliateSelected: false,
      affiliateList: [],
      affiliateFiltered: [],
      affiliateSelected: null
    };
  },

  computed: {
    getApiLink: {
      get() {
        if (this.affiliateData.token) {
          const params = {
            token: this.affiliateData.token,
            affid: this.affiliateData.id
          };

          const queryParams = Object.entries(params)
            .filter(([, value]) => value !== null && value !== undefined)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');

          return `${this.apiDataUrl}/campaign?${queryParams}`;
        } else {
          return null;
        }
      }
    }
  },

  created() {
    this.getAffiliates();
  },

  methods: {
    async getAffiliates() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=AFFILIATE&path=/api/get/affiliate/search?status=3');

        if (status == 200) {
          this.affiliateList = data.map((item) => ({
            name: `${item.name} - ${item.id}`,
            id: item.id
          }));
          this.affiliateFiltered = this.affiliateList;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterAffiliate(val, update) {
      update(this.defaultFilter(val, 'affiliateFiltered', this.affiliateList, 'name'));
    },

    retrieveAffiliateData(data) {
      if (data?.id) {
        this.setupAffiliateData(data.id);
      } else {
        this.wasAffiliateSelected = false;
        this.affiliateData = {
          email: null,
          id: null,
          token: null
        };
      }
    },

    async setupAffiliateData(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(id);
        if (status === 200) {
          this.affiliateData = data;
          this.wasAffiliateSelected = true;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
