<template>
  <div>
    <div v-if="viewAdd" class="q-pa-sm col-xs-12 col-sm-12 col-md-6 col-lg-4">
      <q-form @submit="addPayInfo">
        <q-table hide-pagination :pagination="{ rowsPerPage: 0 }" flat bordered :data="channels" row-key="id" grid>
          <template v-slot:item="props">
            <div class="q-pa-sm col-xs-12 col-sm-12 col-md-6 col-lg-4">
              <q-card flat bordered>
                <q-toolbar
                  class="text-white"
                  :class="
                    props.row.status == 'Ativo'
                      ? 'bg-positive'
                      : props.row.status == 'Desativado'
                      ? 'bg-negative'
                      : 'bg-warning'
                  "
                >
                  <q-toolbar-title class="text-body1"> {{ props.row.name }} ({{ props.row.id }}) </q-toolbar-title>
                  <q-btn
                    v-if="props.row.status == 'Pendente'"
                    flat
                    round
                    dense
                    icon="edit"
                    class="q-mr-xs"
                    @click="
                      ActionSetDialogChannel({
                        value: props.row
                      })
                    "
                  />

                  <q-btn flat round dense icon="mdi-close" @click="deleteClicked(props.row.id)">
                    <q-tooltip content-class="bg-negative text-white" content-style="font-size: 12px">
                      Desativar
                    </q-tooltip>
                  </q-btn>
                </q-toolbar>

                <q-card-section>
                  <q-list dense>
                    <q-item>
                      <q-item-section>
                        <q-item-label>Status:</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="text-uppercase">{{ props.row.status }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>
                        <q-item-label>Tipo de Divulgação:</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{ props.row.site_type }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>
                        <q-item-label>Item Descrição:</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>
                        <div
                            class="text-truncate"
                            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                        >
                            {{ props.row.description }}
                            <q-tooltip
                            content-class="bg-blue text-white"
                            content-style="font-size: 12px; max-width: 300px; white-space: normal; word-wrap: break-word;"
                            >
                            {{ props.row.description }}
                            </q-tooltip>
                        </div>
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>
                        <q-item-label>URL:</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>
                        <div
                            class="text-truncate"
                            style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                        >
                            {{ props.row.url }}
                            <q-tooltip
                            content-class="bg-blue text-white"
                            content-style="font-size: 12px; max-width: 300px; white-space: normal; word-wrap: break-word;"
                            >
                            {{ props.row.url }}
                            </q-tooltip>
                        </div>
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>
                        <q-item-label>Categoria:</q-item-label>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{ props.row.category }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
      </q-form>
    </div>

    <div v-else class="q-pa-sm q-mx-xl row">
      <q-form @submit="addPayInfo">
        <q-card>
          <q-toolbar class="text-white bg-warning">
            <q-toolbar-title class="text-center text-bold"> ATENÇÃO </q-toolbar-title>
          </q-toolbar>
          <q-card-section class="text-bold">
            Para adicionar seu primeiro canal é obrigatório o preenchimento do documento abaixo:
          </q-card-section>

          <q-card-section class="q-pt-none row q-col-gutter-md">
            <div class="col-12">
              <div class="col-3">
                <span class="text-weight-medium q-my-none">Doc*</span>
                <div class="q-gutter-lg">
                  <q-radio v-model="affiliateData.document_type" val="CPF" label="CPF" />
                  <q-radio v-model="affiliateData.document_type" val="CNPJ" label="CNPJ" />
                  <q-radio v-model="affiliateData.document_type" val="INTER" label="Internacional" />
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="q-pt-none row q-col-gutter-md">
                <div class="col-12" v-if="affiliateData.document_type === 'CPF'">
                  <q-input
                    outlined
                    mask="###.###.###-##"
                    unmasked-value
                    label="CPF"
                    v-model="affiliateData.document_number"
                    type="text"
                    lazy-rules
                    :rules="[isRequired, isCPF]"
                  />
                </div>

                <div class="col-12" v-if="affiliateData.document_type === 'CNPJ'">
                  <q-input
                    outlined
                    lazy-rules
                    :rules="[isRequired, isCNPJ]"
                    mask="##.###.###/####-##"
                    unmasked-value
                    label="CNPJ"
                    v-model="affiliateData.document_number"
                    type="text"
                  />
                </div>

                <div class="col-12" v-if="affiliateData.document_type === 'INTER'">
                  <q-input
                    outlined
                    lazy-rules
                    :rules="[isRequired]"
                    maxlength="50"
                    label="Internacional"
                    v-model="affiliateData.document_number"
                    type="text"
                    input-style="text-transform:uppercase"
                  />
                </div>
              </div>
            </div>
          </q-card-section>

          <q-card-actions vertical align="center">
            <q-btn class="q-px-md" color="positive" no-caps unelevated type="submit" label="Salvar informações" />
          </q-card-actions>
        </q-card>
      </q-form>
    </div>
  </div>
</template>

<script>
import ChannelMixin from '../../mixins/ChannelMixin';
import affiliateService from '../../services/AffiliateService';
import UserService from '../../services/UserService';

export default {
  name: 'ListMyChannels',
  mixins: [ChannelMixin, affiliateService, UserService],

  props: {
    viewAdd: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    }
  },

  data() {
    return {
      affiliateData: {
        affiliate_id: null,
        phone_number: '',
        document_number: '',
        business_name: '',
        email: '',
        document_type: 'CPF'
      }
    };
  },

  created() {
    this.getMyChannels();
  },

  methods: {
    async addPayInfo() {
      this.onLoading(true);
      try {
        this.affiliateData.affiliate_id = this.getProfiles.affiliate_id;

        this.affiliateData.phone_number = this.affiliateData.phone_number.replace(/\D/g, '');

        const { status } = await this.createAffiliateDataPayment(this.affiliateData);

        if (status === 200) {
          this.successNotify('Dados cadastrados com sucesso');
          this.$emit('viewAddTrue');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    deleteClicked(channelId) {
      this.showDialog(
        'Desativar Canal',
        'Tem certeza que deseja desativar esse canal? ' +
          '<br> Para ativar novamente é necessário entrar em contato com seu gerente',
        () => {
          this.onOk(channelId);
        },
        () => {
          this.onCancel;
        }
      );
    },

    async onOk(channelId) {
      this.onLoading(true);
      try {
        const { status } = await this.inactiveAffiliateChannel(channelId);
        if (status === 200) {
          this.getMyChannels();
          this.successNotify('Canal desativado com sucesso');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    onCancel() {
      this.successNotify('Ação cancelada');
    }
  }
};
</script>
