<template>
  <q-page padding>
    <header-channel-approve :campaignId="id" @updateStatus="updateStatus(status)" />
    <list-channel-approve :id="id" :status="status" />
  </q-page>
</template>

<script>
import ListChannelApprove from './ListChannelApprove.vue';
import HeaderChannelApprove from './HeaderChannelApprove.vue';

export default {
  name: 'ChannelApproval',
  components: { ListChannelApprove, HeaderChannelApprove },
  props: {
    id: {
      type: [String, Number],
      require: true
    },
    status: []
  },
  methods: {
    updateStatus(status) {
      this.status = status;
    }
  }
};
</script>
