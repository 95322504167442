<template>
  <!-- <div>DEEPLINK</div> -->
  <q-page>
    <q-card flat class="justify-center">
      <q-card-section>
        <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('deeplink_api') }}</q-toolbar-title>
        <q-breadcrumbs class="q-mr-auto q-ma-sm">
          <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
          <q-breadcrumbs-el :label="$t('deeplink_api')" exact />
        </q-breadcrumbs>
      </q-card-section>
      <q-card-section>
        <q-banner class="bg-info text-white q-mb-md" rounded>
          <q-item>
            <q-item-section top avatar>
              <q-icon name="mdi-information-outline" size="lg" />
            </q-item-section>
            <q-item-section>
              <q-item-label caption lines="3" class="text-white">
                Clique
                <a
                  href="https://drive.google.com/file/d/1y4L5MMj5c1cCwEDLN1mzmtpcGzU3X8Si/view"
                  target="_blank"
                  class="q-mt-md"
                  style="text-decoration: none"
                >
                  <strong>aqui</strong>
                </a>
                para saber mais.
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-banner>
      </q-card-section>

      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">
          <select-request
            :v-model="affiliateData.id"
            outlined
            endpoint="/redirect?app=affiliate&path=/api/get/affiliate/search?status=3"
            option-label="name"
            option-value="id"
            :label="$t('affiliate_id')"
            clearable
            @update="(value) => (affiliateData.id = value === null ? 0 : value.id)"
            :formatLabelAffiliate="true"
          />
        </div>

        <div class="col-6">
          <q-input outlined readonly type="text" v-model="affiliateData.token" label="Token">
            <template v-slot:append>
              <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar Token
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
        </div>

        <div class="col-6">
          <q-select
            :disable="!wasAffiliateSelected"
            v-model="channel"
            outlined
            clearable
            option-value="id"
            option-label="name"
            :label="$t('select_channel')"
            @input="(val) => (val ? (channelID = val.id) : (channelID = null))"
            @clear="(val) => (channel = null)"
            :options="affiliateChannels"
          />
        </div>

        <div class="col-6">
          <q-input outlined readonly type="text" v-model="channelID" :label="$t('channel_id')"> </q-input>
        </div>

        <div class="col-6">
          <q-select
            :disable="!wasAffiliateSelected"
            v-model="campaign"
            outlined
            clearable
            option-value="campaign_id"
            option-label="campaign_name"
            :label="$t('select_campaign')"
            @input="(val) => (val ? (campaignID = val.campaign_id) : (campaignID = null))"
            @clear="(val) => (campaign = null)"
            :options="affiliateCampaigns"
          />
        </div>

        <div class="col-6">
          <q-input outlined readonly type="text" v-model="campaignID" :label="$t('campaign_id')"> </q-input>
        </div>

        <div class="col-12">
          <div class="col-12">
            <q-input
              outlined
              type="text"
              v-model="url"
              @input="validateAndEncodeUrl"
              @clear="(value) => (encodedUrl = '')"
              label="URL"
              clearable
            />
          </div>
        </div>

        <div class="col-12">
          <q-input outlined readonly type="text" :value="getApiLink" :label="$t('shortned_link_api_generator')">
            <template v-slot:append>
              <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLink)">
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar API
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
        </div>
        <div class="col-12">
          <q-input
            outlined
            readonly
            type="text"
            :value="getApiLinkAutoRedirect"
            :label="$t('auto_redirect_api_generator')"
          >
            <template v-slot:append>
              <q-btn
                color="primary"
                no-caps
                unelevated
                icon="mdi-content-copy"
                @click="copyData(getApiLinkAutoRedirect)"
              >
                <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Copiar API
                </q-tooltip>
              </q-btn>
            </template>
          </q-input>
        </div>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import AffiliateService from '../../../services/AffiliateService';
import AdvertiserService from '@/services/AdvertiserService';
import AffiliateMixin from '../../../mixins/AffiliateMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';

const dataLink = process.env.VUE_APP_DATA_API;

export default {
  name: 'DeeplinkAPIPage',
  components: { SelectRequest },
  mixins: [AffiliateService, AffiliateMixin, AdvertiserService],
  watch: {
    'affiliateData.id'() {
      this.retrieveAffiliateData(this.affiliateData.id);
    }
  },
  data() {
    return {
      apiDataUrl: dataLink,
      optionsDisplay: ['CSV', 'JSON'],
      channel: null,
      channelID: null,
      campaign: null,
      campaignID: null,
      url: null,
      encodedUrl: null,
      affiliateData: {
        email: null,
        id: null,
        token: null
      },
      affiliateChannels: [],
      affiliateCampaigns: [],
      wasAffiliateSelected: false
    };
  },
  computed: {
    getApiLink: {
      get() {
        if (this.channelID && this.encodedUrl && this.campaignID) {
          return `${this.apiDataUrl}/deeplink?token=${this.affiliateData.token}&canalid=${this.channelID}&affid=${this.affiliateData.id}&progID=${this.campaignID}&url=${this.encodedUrl}`;
        }
        return null;
      }
    },
    getApiLinkAutoRedirect: {
      get() {
        if (this.channelID && this.encodedUrl && this.campaignID) {
          return `${this.apiDataUrl}/v3/deeplink?token=${this.affiliateData.token}&canalid=${this.channelID}&affid=${this.affiliateData.id}&progID=${this.campaignID}&url=${this.encodedUrl}`;
        }
        return null;
      }
    }
  },

  methods: {
    async validateAndEncodeUrl(value) {
      if (value == '') this.encodedUrl = '';
      try {
        var url = new URL(value);
        if (url.protocol == 'https:') {
          this.encodedUrl = encodeURIComponent(url.toString());
        }
        return;
      } catch (error) {
        return;
      }
    },

    retrieveAffiliateData(id) {
      if (id) {
        this.setupAffiliateData(id);
      } else {
        this.affiliateChannels = [];
        this.affiliateCampaigns = [];
        this.wasAffiliateSelected = false;
        this.channel = null;
        this.campaign = null;
        this.channelID = null;
        this.campaignID = null;
        this.affiliateData = {
          email: null,
          id: null,
          token: null
        };
      }
    },

    async setupAffiliateData(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(id);
        if (status === 200) {
          this.affiliateData = data;
          this.wasAffiliateSelected = true;
          this.channel = null;
          this.setupAffiliateChannels(id);
          this.setupAffiliateCampaigns(id);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async setupAffiliateChannels(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateSiteActiveListByID(id);
        if (status == 200) {
          this.affiliateChannels = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },
    async setupAffiliateCampaigns(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.getCampaignListAffiliate(id);
        if (status === 200) {
          this.affiliateCampaigns = data;
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
