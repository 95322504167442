<template>
  <q-page padding>
    <q-toolbar class="q-px-md q-mb-md">
      <div class="q-mr-auto">
        <q-toolbar-title class="text-weight-medium text-h5">Dashboard </q-toolbar-title>

        <q-breadcrumbs class="">
          <q-select
            v-model="selectedVision"
            map-options
            :options="[
              { label: 'Visão Geral', value: 'general' },
              { label: 'Detalhes da Campanha', value: 'campaign' },
              { label: 'Detalhes de Canais', value: 'channel' }
            ]"
            emit-value
            style="width: 180px"
            dense
            class="text-caption"
            hide-bottom-space
            borderless
          />
        </q-breadcrumbs>
      </div>

      <q-checkbox
        label="Ignorar campanhas de teste"
        true-value="1"
        v-if="selectedVision == 'general'"
        false-value="0"
        v-model="ignoreTestCampaigns"
        class="q-mr-sm"
      />
      <date-input
        buttons
        class="q-mr-sm"
        dense
        outlined
        label="Período"
        :v-model="period"
        @update="
          (val) => {
            period = val;
          }
        "
        today-btn
        range
      />

      <q-btn-dropdown
        no-caps
        outline
        class="q-mr-sm"
        content-style="width: 380px"
        :menu-offset="[0, 10]"
        v-if="selectedVision == 'general'"
        label="Filtros"
      >
        <div style="" class="row">
          <div
            class="col-12 justify-between items-center row"
            v-for="(filter, index) in Object.values(filters)"
            :key="index"
          >
            <div class="items-center flex q-pa-md" rounded>{{ filter.label }}</div>
            <q-btn
              color="white"
              text-color="blue"
              @click="filter.editing = !filter.editing"
              unelevated
              :icon="filter.editing ? 'keyboard_arrow_left' : 'keyboard_arrow_down'"
              style="font-size: 12px"
              round
              no-caps
            />
            <transition name="fade">
              <div class="col-12 q-pa-md" style="width: full" v-if="filter.editing">
                <q-select
                  v-model="filter.value"
                  :options="filter.name == 'statuses' ? filter.options : filter.optionsFiltered"
                  map-options
                  emit-value
                  :label="filter.label"
                  :option-label="filter.option_label"
                  :option-value="filter.option_value"
                  outlined
                  clearable
                  use-input
                  @filter="(val, update) => update(selectFilter(val, index, filter.option_label))"
                />
              </div>
            </transition>
          </div>
        </div>
        <div class="flex justify-center q-pa-md q-mt-sm">
          <q-btn label="Remover Filtros" unelevated color="black" style="width: 160px" @click="resetFilters" />
        </div>
      </q-btn-dropdown>
      <q-select
        v-if="selectedVision == 'channel'"
        v-model="filters.channel.value"
        outlined
        clearable
        dense
        class="q-mr-sm relative-position"
        @clear="(val) => (filters.channel.value = '')"
        @filter="
          (val, update) => {
            filters.channel.value = '';
            update(selectFilter(val, 4, filters.channel.option_label));
          }
        "
        use-input
        map-options
        :label="filters.channel.label"
        :display-value="
          filters.channel.value == ''
            ? ''
            : filters.channel.value.name.length > 20
            ? filters.channel.value.name.slice(0, 14) + '...'
            : filters.channel.value.name
        "
        input-class="absolute"
        style="width: 240px"
        :options="filters.channel.optionsFiltered"
        :option-label="filters.channel.option_label"
        :option-value="filters.channel.option_value"
      >
      </q-select>

      <q-select
        v-if="selectedVision == 'campaign'"
        v-model="filters.campaign.value"
        outlined
        clearable
        dense
        class="q-mr-sm relative-position"
        @clear="(val) => (filters.campaign.value = '')"
        @filter="
          (val, update) => {
            filters.campaign.value = '';
            update(selectFilter(val, 3, filters.campaign.option_label));
          }
        "
        use-input
        map-options
        :label="filters.campaign.label"
        :display-value="
          filters.campaign.value == ''
            ? ''
            : filters.campaign.value.name.length > 20
            ? filters.campaign.value.name.slice(0, 14) + '...'
            : filters.campaign.value.name
        "
        input-class="absolute"
        style="width: 240px"
        :options="filters.campaign.optionsFiltered"
        :option-label="filters.campaign.option_label"
        :option-value="filters.campaign.option_value"
      >
      </q-select>

      <q-select
        v-if="selectedVision !== 'general'"
        v-model="filters.status.value"
        outlined
        clearable
        class="q-mr-md"
        style="width: 180px"
        dense
        map-options
        emit-value
        :label="filters.status.label"
        :options="filters.status.options"
        :option-label="filters.status.option_label"
        :option-value="filters.status.option_value"
      />

      <q-btn
        color="primary"
        padding="7px 7px"
        unelevated
        icon="search"
        dense
        class=""
        @click="retrieveDashboardResume"
      />
    </q-toolbar>

    <GeneralVision
      v-if="selectedVision === 'general'"
      :filters="filters"
      :period="period"
      :ignoreTestCampaigns="ignoreTestCampaigns"
      ref="generalVision"
    />

    <CampaignVision
      v-if="selectedVision === 'campaign'"
      :filters="filters"
      :period="period"
      :ignoreTestCampaigns="ignoreTestCampaigns"
      ref="campaignVision"
    />

    <ChannelVision
      v-if="selectedVision === 'channel'"
      :filters="filters"
      :period="period"
      :ignoreTestCampaigns="ignoreTestCampaigns"
      ref="channelVision"
    />
  </q-page>
</template>

<script>
import DateInput from '@/widgets/inputsGlobal/DateInput.vue';
import DashboardResumeService from '@/services/DashboardResume.js';
import AffiliateService from '@/services/AffiliateService.js';
import AdvertiserService from '@/services/AdvertiserService.js';
import UserService from '@/services/UserService.js';
import GeneralVision from '@/components/smartDashboard/visions/GeneralVision.vue';
import CampaignVision from '@/components/smartDashboard/visions/CampaignVision.vue';
import ChannelVision from '@/components/smartDashboard/visions/ChannelVision.vue';

export default {
  name: 'SmartDashboard',
  components: {
    DateInput,
    GeneralVision,
    CampaignVision,
    ChannelVision
  },
  mixins: [DashboardResumeService, AffiliateService, AdvertiserService, UserService],
  data() {
    return {
      selectedVision: 'general',
      period: '',
      ignoreTestCampaigns: '1',
      filters: {
        status: {
          label: 'Status',
          name: 'statuses',
          value: '',
          editing: false,
          options: [
            { label: 'Aprovado', value: 2 },
            { label: 'Pendente', value: 1 },
            { label: 'Recusado', value: 0 }
          ]
        },
        campaign_manager: {
          label: 'Gerente de campanha',
          name: 'campaign_manager',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'user_id'
        },
        affiliate_manager: {
          label: 'Gerente de afiliados',
          name: 'affiliate_manager',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'user_id'
        },
        campaign: {
          label: 'Campanha',
          name: 'campaigns',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'id'
        },
        channel: {
          label: 'Canal',
          name: 'channels',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'id'
        },
        segmentation: {
          label: 'Segmentação de campanha',
          name: 'segmentation',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'description',
          option_value: 'id'
        }
      }
    };
  },
  methods: {
    async retrieveDashboardResume() {
      if (this.selectedVision == 'general') {
        this.$refs.generalVision.getGeneralDashboardResume();
      } else if (this.selectedVision === 'campaign') {
        if (this.filters.campaign.value) {
          this.$refs.campaignVision.getCampaignDashboardResume();
          return;
        }
        this.errorNotify('O filtro campanha é obrigatório.');
      } else if (this.selectedVision === 'channel') {
        if (this.filters.channel.value) {
          this.$refs.channelVision.getChannelDashboardResume();
          return;
        }
        this.errorNotify('O filtro de canal é obrigatório.');
      }
    },

    periodString() {
      var [startDate, endDate] = this.period.split(' - ');
      return `start_date%3D${this.formatToEn(startDate)}%26end_date%3D${this.formatToEn(endDate)}%26`;
    },
    selectFilter(val, index, prop = null) {
      const needle = val.toLocaleLowerCase();
      const filters = Object.values(this.filters);
      filters[index].optionsFiltered = needle
        ? filters[index].options.filter((v) =>
            prop ? v[prop].toLocaleLowerCase().includes(needle) : v.toLocaleLowerCase().includes(needle)
          )
        : filters[index].options;
    },

    async retrieveSelectOptions() {
      try {
        this.onLoading(true);
        const options = await Promise.all([
          this.getManagersOptions(),
          this.getAffiliateManagerOptions(),
          this.getAllCampaigns(),
          this.getAllSites(),
          this.getSegmentationsList()
        ]);

        this.filters['campaign_manager'].options = this.filters['campaign_manager'].optionsFiltered = options[0].data;
        this.filters['affiliate_manager'].options = this.filters['affiliate_manager'].optionsFiltered = options[1].data;
        this.filters['campaign'].options = this.filters['campaign'].optionsFiltered = options[2].data;
        this.filters['channel'].options = this.filters['channel'].optionsFiltered = options[3].data;
        this.filters['segmentation'].options = this.filters['segmentation'].optionsFiltered = options[4].data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    resetFilters() {
      Object.values(this.filters).forEach((filter) => {
        filter.value = '';
      });
    }
  },

  watch: {
    selectedVision() {
      this.resetFilters();
    }
  },

  created() {
    this.period = `${this.$moment().subtract(7, 'days').format('L')} - ${this.$moment().format('L')}`;
  },

  mounted() {
    this.retrieveSelectOptions();
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fadeVision-enter-active,
.fadeVision-leave-active {
  transition: opacity 1s;
}
.fadeVision-enter, .fadeVision-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
