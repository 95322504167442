<template>
  <div>
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <q-toolbar-title>Buscar Por </q-toolbar-title>
        <q-btn outline color="primary" icon-right="archive" class="q-mr-sm" no-caps @click="exportCsv()" unelevated>
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
            {{ $t('export_csv') }}
          </q-tooltip>
        </q-btn>
      </q-toolbar>

      <q-dialog v-model="dialog" persistent>
        <q-card style="min-width: 350px">
          <q-card-section class="q-mt-md">
            <div class="text-subtitle1">
              Confirma a <b>{{ dialogParam }}</b> de todos os <b>{{ channels.length }}</b> canais?
            </div>
          </q-card-section>
          <q-card-actions align="right" class="q-pa-md">
            <q-btn
              outline
              color="primary"
              no-caps
              :label="$t('cancel')"
              padding="sm lg"
              @click="dialogParam = null"
              v-close-popup
            />
            <q-btn
              unelevated
              no-caps
              padding="sm lg"
              label="Confirmar"
              color="green"
              @click="updateStatusChannels()"
              v-close-popup
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-card flat bordered>
            <q-card-section>
              <span class="text-weight-medium">Status</span>
              <div class="q-gutter-sm">
                <q-checkbox v-model="params.status" val="0" :label="pendingCount" color="warning" />
                <q-checkbox v-model="params.status" val="1" :label="approvalCount" color="primary" />
                <q-checkbox v-model="params.status" val="2" :label="pausedCount" color="accent" />
                <q-checkbox v-model="params.status" val="3" :label="reprovedCount" color="red" />
                <q-checkbox v-model="params.status" val="4" :label="deletedCount" color="negative" />
              </div>
            </q-card-section>
          </q-card>

          <q-td v-if="params.status.length === 1 && channels.length > 1">
            <q-btn
              unelevated
              round
              flat
              icon="mdi-thumb-up"
              color="primary"
              :disable="params.status[0] === '1'"
              @click="openDialog(`aprovação`)"
            >
              <q-tooltip
                v-if="params.status[0] != '1'"
                content-class="bg-secondary text-white"
                content-style="font-size: 12px"
              >
                Aprovar Todos</q-tooltip
              >
            </q-btn>

            <q-btn
              unelevated
              round
              flat
              icon="mdi-pause"
              color="primary"
              :disable="params.status[0] === '2' || params.status[0] === '0'"
              @click="openDialog(`pausa`)"
            >
              <q-tooltip
                v-if="params.status[0] != '2' && params.status[0] != '0'"
                content-class="bg-secondary text-white"
                content-style="font-size: 12px"
              >
                Pausar Todos</q-tooltip
              >
            </q-btn>

            <q-btn
              unelevated
              round
              flat
              icon="mdi-thumb-down"
              color="primary"
              :disable="params.status[0] != '0'"
              @click="openDialog(`reprovação`)"
            >
              <q-tooltip
                v-if="params.status[0] == '0'"
                content-class="bg-secondary text-white"
                content-style="font-size: 12px"
              >
                Reprovar Todos</q-tooltip
              >
            </q-btn>

            <q-btn
              unelevated
              round
              flat
              icon="mdi-delete-empty"
              color="primary"
              :disable="params.status[0] === '4' || params.status[0] === '0'"
              @click="openDialog(`remoção`)"
            >
              <q-tooltip
                v-if="params.status[0] != '4' && params.status[0] != '0'"
                content-class="bg-secondary text-white"
                content-style="font-size: 12px"
              >
                Remover Todos</q-tooltip
              >
            </q-btn>
          </q-td>
        </div>

        <div class="col-3">
          <q-input outlined label="ID do Canal" v-model="params.idChannel" type="text" />
        </div>
        <div class="col-3">
          <q-input outlined label="Nome do Canal" v-model="params.channelName" type="text" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';

export default {
  name: 'HeaderChannelApprove.vue',
  mixins: [CampaignMixin],
  props: {
    campaignId: {
      type: [String, Number],
      require: true
    }
  },
  watch: {
    params: {
      handler() {
        this.onFilterChannels();
      },
      deep: true
    }
  },
  data() {
    return {
      filter: true,
      dialog: false,
      dialogParam: null,
      params: {
        idChannel: null,
        channelName: null,
        status: []
      }
    };
  },

  methods: {
    openDialog(param) {
      this.dialogParam = param;
      this.dialog = true;
    },

    async updateStatusChannels() {
      let newStatus = 0;
      switch (this.dialogParam) {
        case 'aprovação':
          newStatus = 1;
          break;
        case 'pausa':
          newStatus = 2;
          break;
        case 'reprovação':
          newStatus = 3;
          break;
        case 'remoção':
          newStatus = 4;
          break;
      }

      try {
        this.onLoading(true);
        const { data, status } = await this.$http.post('/v2/redirect', {
          app: 'ADVERTISER',
          path: '/api/edit/campaign/channels_selecteds',
          params: {
            data: {
              ids: this.channels.map((item) => item.campaign_site_id),
              status: newStatus
            }
          }
        });
        if (status === 200) {
          this.successNotify(data.message);
          this.dialog = false;
          this.params.status = [];
          this.getChannelsById(this.campaignId);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    onFilterChannels() {
      this.search = null;
      let channels = this.channelsAll;

      if (this.params.idChannel) channels = channels.filter((item) => item.site_id == this.params.idChannel);

      if (this.params.channelName)
        channels = channels.filter((item) =>
          item.site_name.toLowerCase().includes(this.params.channelName.toLowerCase())
        );

      if (this.params.status.length > 0) {
        channels = channels.filter((item) => this.params.status.includes(item.campaign_site_status.toString()));
      }

      this.$emit('updateStatus', this.params.status);
      this.ActionSetChannels(channels);
    },

    exportCsv() {
      this.exportTableCsv(this.channels, this.columns, 'canais_aprovados_csv');
    }
  },

  computed: {
    pendingCount() {
      return `Pendente (${this.channelsStatusCount.pending})`;
    },
    approvalCount() {
      return `Aprovado (${this.channelsStatusCount.approved})`;
    },
    pausedCount() {
      return `Pausado (${this.channelsStatusCount.paused})`;
    },
    reprovedCount() {
      return `Reprovado (${this.channelsStatusCount.reproved})`;
    },
    deletedCount() {
      return `Removido (${this.channelsStatusCount.deleted})`;
    }
  }
};
</script>
