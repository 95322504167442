<template>
  <div class="q-px-md">
    <div class="row justify-around">
      <div class="row q-col-gutter-x-md">
        <div class="col-3" v-for="(data, index) in summariesData" :key="index">
          <SummaryCard :selectedVision="'general'" :data="data" :key="index" />
        </div>
      </div>
    </div>

    <div class="row q-col-gutter-x-md q-mt-lg">
      <div class="col">
        <SummaryTable
          :data="commissionPerCampaign"
          :title="'Full por Campanha'"
          :columns="commissionTableColumns"
          :type="'campaign'"
        />
      </div>
      <div class="col">
        <SummaryTable
          :data="commissionPerChannel"
          :title="'Full por Canal'"
          :columns="commissionTableColumns"
          :type="'channel'"
        />
      </div>
    </div>
    <div class="row q-col-gutter-x-md q-mt-lg">
      <div class="col-4">
        <SummaryBarChart :data="commissionPerMonthData" />
      </div>
      <div class="col-4">
        <SummaryPizzaChart
          :title="'Status das Conversões'"
          :id="'commission-full-by-status'"
          :data="commissionPerStautsData"
        />
      </div>
      <div class="col-4">
        <SummaryLineChart :data="commissionPerDay" :title="'Comissão Full Por Dia'" :dataProperty="'comission_full'" />
      </div>
    </div>
  </div>
</template>

<script>
import SummaryCard from '@/components/smartDashboard/SummaryCard.vue';
import SummaryTable from '@/components/smartDashboard/SummaryTable.vue';
import SummaryBarChart from '@/components/smartDashboard/SummaryBarChart.vue';
import SummaryPizzaChart from '@/components/smartDashboard/SummaryPizzaChart.vue';
import SummaryLineChart from '@/components/smartDashboard/SummaryLineChart.vue';
import DashboardResumeService from '@/services/DashboardResume.js';

export default {
  name: 'GeneralVision',
  components: {
    SummaryCard,
    SummaryTable,
    SummaryBarChart,
    SummaryPizzaChart,
    SummaryLineChart
  },
  mixins: [DashboardResumeService],

  props: {
    filters: {
      require: true,
      type: [Object]
    },
    period: {
      require: true,
      type: [String]
    },
    ignoreTestCampaigns: {
      require: true,
      type: [Boolean, String]
    }
  },

  data() {
    return {
      commissionTableColumns: [
        {
          name: 'name',
          label: 'Nome',
          field: 'name',
          align: 'center',
          classes: 'text-blue text-bold ellipsis',
          style: 'max-width: 200px'
        },
        { name: 'commission_full', label: 'Valor', field: 'comission_full', align: 'center' },
        { name: 'percentage', label: '%', field: 'percentage', align: 'center' }
      ],
      commissionPerCampaign: [],
      commissionPerChannel: [],
      commissionPerMonthData: [],
      commissionPerStautsData: [],
      commissionPerDay: [],
      summariesData: {
        comission_full: { title: 'Comissão Full', value: '', color: '#17ccf3', icon: 'mdi-trending-up', type: 'money' },
        cost: { title: 'Custo', value: '', color: '#f8aa4c', icon: 'mdi-cash', type: 'money' },
        margin: { title: 'Margem', value: '', color: '#FBCB42', icon: 'mdi-chart-line', type: 'money' },
        margin_percent: {
          title: '% Margem',
          value: '',
          color: '#766BFB',
          icon: 'mdi-percent-outline',
          type: 'percent'
        }
      }
    };
  },

  methods: {
    async getGeneralDashboardResume() {
      try {
        this.onLoading(true);
        var filterString = '%3F';
        var filterChartsString = '%3F';
        filterString += this.periodString();
        Object.values(this.filters).forEach((filter) => {
          if (filter.value !== '' && filter.value !== null) {
            filterString += filter.name + '%3D' + filter.value + '%26';
            filterChartsString += filter.name + '%3D' + filter.value + '%26';
          }
        });
        filterString += 'ignore_test%3D' + this.ignoreTestCampaigns;
        filterChartsString += 'ignore_test%3D' + this.ignoreTestCampaigns;

        const data = await Promise.all([
          this.getSmartDashboardResume(filterString),
          this.getCampaignCommissionFull(filterString),
          this.getChannelCommissionFull(filterString),
          this.getCommissionPerMonth(filterChartsString),
          this.getCommissionByStatus(filterString),
          this.getCommissionFullDaily(filterChartsString)
        ]);

        for (const key in data[0].data) {
          this.summariesData[key].value = data[0].data[key];
        }

        this.commissionPerCampaign = data[1].data;
        this.commissionPerChannel = data[2].data;
        this.commissionPerMonthData = data[3].data;
        this.commissionPerStautsData = data[4].data.sort((a, b) => a.status - b.status);
        this.commissionPerDay = data[5].data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  mounted() {
    this.getGeneralDashboardResume();
  }
};
</script>

<style></style>
